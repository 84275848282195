<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:45
-->
<template>
	<div class="baseVerify">
		<common-title :title="'共建基地审核'" />
		<div class="baseVerify-content">
			<div class="common-table">
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">共建基地ID</span>
						<el-input readonly="readonly" v-model="baseVerify.id" placeholder="请输入"
							class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">当前状态</span>
						<span
							class="common-table-text">{{baseVerify.auditStatus == 0? "审核中":baseVerify.auditStatus == 1? "已通过":"未通过"}}</span>
						<!-- <el-input v-model="baseVerify.auditStatus" placeholder="请输入" class="common-table-val" /> -->
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">基地名称</span>
						<el-input readonly="readonly" v-model="baseVerify.name" placeholder="请输入"
							class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">基地所在组织</span>
						<el-input v-model="baseVerify.organization" placeholder="请输入" class="common-table-val" />
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">基地详情</span>
						<quill-editor :options="editorOption" class="common-table-val" :style="{'height': '400px'}" v-model="baseVerify.details"
							@focus="editorFocus($event)">
						</quill-editor>
						<!-- <el-input readonly="readonly" type="textarea" v-model="baseVerify.plan"
							placeholder="请输入" class="common-table-val" /> -->
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">审批操作</span>
						<div class="common-table-verify">
							<div class="table-verify-item" style="margin: 0;">
								<span class="verify-item-label">是否通过</span>
								<el-radio-group v-model="isAgree">
									<el-radio :label="1">通过</el-radio>
									<el-radio :label="2">不通过</el-radio>
								</el-radio-group>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="baseVerify-btn">
				<el-button v-preventReClick @click="submitVerify" type="primary">确定</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import storage from 'store'
	import {
		verifyBase
	} from "@/api/base"
	export default {
		data() {
			return {
				baseVerify: {},
				isAgree: 1,
				editorOption: {
					placeholder: "暂无内容......",
				}
			};
		},
		mounted() {
			this.baseVerify = storage.get('verifyBase')
		},
		beforeRouteLeave(to,from,next){
			to.meta.isAlive = true
			next()
		},
		methods: {
			editorFocus(e) {
				e.enable(false);
			},
			submitVerify() {
				let params = {
					id: this.baseVerify.id,
					state: this.isAgree
				}
				console.log("submitVerify", params)
				verifyBase(this.baseVerify.id, params).then((res) => {
					if (res.code == 0) {
						this.$message.success("共建基地审核成功~")
						this.$router.go(-1)
						storage.remove('verifyBase')
					}
				})
			},
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	/deep/.common-table-row .el-textarea__inner {
		resize: none;
		height: 400px;
		border: 0;
	}

	.baseVerify {
		.baseVerify-content {
			padding: 0 20px 20px 20px;

			.baseVerify-btn {
				margin-top: 20px;
			}
		}
	}
</style>
